import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { requestPermission, onMessageListener } from "../firebase/firebase"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const AppointmentForm = () => {
    const [data, setData] = useState([]);
    const [selectedAstrologers, setSelectedAstrologers] = useState([]);
    const [availableAstrologers, setAvailableAstrologers] = useState([]);
    const [apt_date, setAptDate] = useState('')
    const [apt_time, setAptTime] = useState('')
    const [apt_user_name, setAptUserName] = useState('')
    const [apt_user_mobile, setAptUserMobile] = useState('')
    const [apt_user_email, setAptUserEmail] = useState('')
    const [apt_user_address, setAptUserAddress] = useState('')
    const [apt_user_dob, setAptUserDob] = useState('')
    const [apt_user_tob, setAptUserTob] = useState('')
    const [apt_user_country, setAptUserCountry] = useState('')
    const [apt_user_state, setAptUserState] = useState('')
    const [apt_user_dist, setAptUserDist] = useState('')
    const [appt_user_village, setAptUserVillage] = useState('')
    const [apt_user_pob, setAptUserPob] = useState('')
    const [apt_mode, setAptMode] = useState('')
    const [formData, setFormData] = useState({
        apt_services: [],
    });
    const [deviceToken, setDeviceToken] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [aggreed, setAggreed] = useState()
    const [signature, setSignature] = useState()
    const [signatureStatus, setSignatureStatus] = useState()

    useEffect(() => {
        // Get the current date and time in IST
        const currentDate = new Date().toLocaleDateString('en-GB', {
            timeZone: 'Asia/Kolkata',
        });
        const currentTime = new Date().toLocaleTimeString('en-GB', {
            hour12: false,
            timeZone: 'Asia/Kolkata',
        });

        // Format the date to YYYY-MM-DD and time to HH:MM
        const formattedDate = currentDate.split('/').reverse().join('-'); // Converts DD/MM/YYYY to YYYY-MM-DD
        const formattedTime = currentTime.substring(0, 5); // Extract HH:MM from HH:MM:SS

        setAptDate(formattedDate);
        setAptTime(formattedTime);
    }, []);



    useEffect(() => {
        const MAX_RETRIES = 15; // Maximum retries for fetching token
        let retryCount = 0; // Track retry attempts

        const fetchToken = () => {
            requestPermission()
                .then((token) => {
                    if (token) {
                        setDeviceToken(token); // Update state
                        localStorage.setItem("deviceToken", token); // Save to localStorage
                        console.log("FCM Token successfully retrieved:", token);
                    } else if (retryCount < MAX_RETRIES) {
                        retryCount += 1;
                        console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
                        setTimeout(fetchToken, 1000); // Retry after 1 second
                    } else {
                        console.error("Failed to fetch device token after retries.");
                    }
                })
                .catch((err) => {
                    console.error("Error retrieving FCM Token:", err);
                    if (retryCount < MAX_RETRIES) {
                        retryCount += 1;
                        console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
                        setTimeout(fetchToken, 1000); // Retry after 1 second
                    }
                });
        };

        // Initial token fetch
        fetchToken();

        // Listen for foreground messages
        onMessageListener()
            .then((payload) => {
                console.log("Message received in foreground:", payload);
                new Notification(payload.notification.title, {
                    body: payload.notification.body,
                });
            })
            .catch((err) => {
                console.error("Error listening to foreground messages:", err);
            });
    }, []);


    useEffect(() => {
        fetch('https://alpastrology.online/alpapi/User_api/astrologer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'alp@123',
            },
        })
            .then(response => response.json())
            .then(responseData => {
                console.log('Fetched astrologer data:', responseData);
                if (responseData.status === "Success" && Array.isArray(responseData.data.astrologer)) {
                    setData(responseData.data.astrologer);
                } else {
                    console.error('Expected an array but got:', responseData);
                    setData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching astrologers:', error);
                setData([]);
            });
    }, []);


    const fetchAvailableAstrologer = useCallback(() => {
        if (!apt_date || !apt_time) {
            console.error("Both date and time are required to fetch astrologers.");
            return;
        }

        // Prepare FormData
        const formDataToSend = new FormData();
        formDataToSend.append('date', apt_date);
        formDataToSend.append('time', apt_time);

        fetch('https://alpastrology.online/alpapi/User_api/get_available_astrologer', {
            method: 'POST',
            headers: {
                'x-api-key': 'alp@123',
            },
            body: formDataToSend,
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log('Fetched available astrologers:', responseData);
                if (responseData.status === "Success" && Array.isArray(responseData.data)) {
                    setAvailableAstrologers(responseData.data); // Update state with astrologers
                } else {
                    console.error('Unexpected response format:', responseData);
                    setAvailableAstrologers([]); // Clear astrologers if data is not as expected
                }
            })
            .catch((error) => {
                console.error('Error fetching available astrologers:', error);
                setAvailableAstrologers([]); // Clear astrologers on error
            });
    }, [apt_date, apt_time]); // Dependency array

    useEffect(() => {
        if (apt_date && apt_time) {
            fetchAvailableAstrologer();
        }
    }, [apt_date, apt_time, fetchAvailableAstrologer]);




    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === 'apt_services[]') {
            if (checked) {
                setFormData({ ...formData, apt_services: [...formData.apt_services, value] });
            } else {
                setFormData({ ...formData, apt_services: formData.apt_services.filter(service => service !== value) });
            }
        }
    };

    const handleAstrologerSelection = (userId) => {
        setSelectedAstrologers(prevSelected => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter(id => id !== userId);
            } else if (prevSelected.length < 2) {
                return [...prevSelected, userId];
            }
            return prevSelected;
        });
    };


    useEffect(() => {
        const placeOfBirth = `${appt_user_village || ''}, ${apt_user_dist || ''}, ${apt_user_state || ''}, ${apt_user_country || ''}`.replace(/(^, )|((, )+$)/g, ''); // Handle empty values
        setAptUserPob(placeOfBirth);
    }, [apt_user_country, apt_user_state, apt_user_dist, appt_user_village]);

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|online|org|net|edu|gov|info|io|co|in)$/;
        const emailError = document.getElementById('emailError'); // Ensure this ID is unique

        if (emailRegex.test(email)) {
            // If valid, update the state and hide the error message
            setAptUserEmail(email);
            if (emailError) emailError.style.display = 'none';
        } else {
            // If invalid, show the error message
            if (emailError) emailError.style.display = 'block';
        }
    };



    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        validateEmail()
        // Validate required fields
        if (!apt_date || !apt_time || !apt_user_name || !apt_user_mobile || !apt_user_email || !apt_mode) {
            alert("Please fill in all required fields.");
            return;
        }

        if (selectedAstrologers.length === 0) {
            alert("Please select at least one astrologer.");
            return;
        }

        if (signatureStatus !== "Accept") {
            alert("Please accept the terms before submitting.");
            return;
        }

        const form = new FormData();
        form.append('apt_date', apt_date);
        form.append('apt_time', apt_time);
        form.append('apt_user_name', apt_user_name);
        form.append('apt_user_mobile', apt_user_mobile);
        form.append('apt_user_email', apt_user_email);
        form.append('apt_user_address', apt_user_address);
        form.append('apt_user_dob', apt_user_dob);
        form.append('apt_user_tob', apt_user_tob);
        form.append('apt_user_pob', apt_user_pob);
        form.append('apt_mode', apt_mode);
        form.append('user_device_token', deviceToken)
        form.append('aggreed', aggreed)
        form.append('signature', signature)
        form.append('signature_status', signatureStatus)

        // Ensure apt_services is an array
        formData.apt_services.forEach(service => {
            form.append('apt_services[]', service);
        });

        selectedAstrologers.forEach(astrologer => {
            form.append('asign_id[]', astrologer);
        });

        console.log('Form Data to be submitted:', form); // Log the form data
        setIsSubmitting(true);

        try {
            const response = await fetch('https://alpastrology.online/alpapi/User_api/appointment', {
                method: 'POST',
                headers: {
                    'x-api-key': 'alp@123',
                },
                body: form,
            });

            const text = await response.text();
            console.log('Response text:', text);

            try {
                const responseData = JSON.parse(text);
                console.log('Form submission response:', responseData);

                if (responseData.status === "Success") {

                    const notifData = new FormData();
                    notifData.append('title', 'ALP Online');
                    notifData.append('message', apt_user_name + ' Sent a new Appointment request');

                    axios.post(
                        'https://alpastrology.online/alpapi/User_api/notification_send',
                        notifData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'x-api-key': 'alp@123',
                            },
                        }
                    )


                    alert('Appointment booked successfully!');
                    setFormData({
                        apt_services: [],
                    });
                    setAptUserName('')
                    setSelectedAstrologers([]);
                    window.location.reload();
                } else {
                    alert('Error submitting form. Please try again.');
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
                alert('Error parsing response. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form. Please try again.');
        }
    };



    return (
        <Container className='appt_cont'>
            <Row>
                <Col lg={12}>
                    <h3>Astrologers</h3>
                    <Row>
                        {data.length > 0 ? (
                            data.map((astrologer, index) => {
                                let className = '';
                                if (astrologer.admin_live_status === "0") {
                                    className = 'astrologer_online';
                                } else {
                                    className = 'astrologer_offline';
                                }
                                return (
                                    <Col lg='2' md='3' sm='3' xs='5' className='m-1' key={index}>
                                        <div>
                                            <img src={astrologer.astrologers_image} alt={astrologer.astrologers_name} width={100} className={className} data-aos="flip-right" data-aos-duration="1250" />
                                        </div>
                                    </Col>
                                );
                            })
                        ) : (
                            <p>No astrologers available.</p>
                        )}
                    </Row>
                </Col>
            </Row>
            <h4 className='display-5 my-2 py-2 fw-bold mt-2 text-center'>Make an Appointment</h4>
            <Form className="as_appointment_form  my-4" method="post" action="" onSubmit={handleSubmit}>
                <Row>
                    <Col lg={6} md={6} sm={12} className="as_font14_subhead m-auto" data-aos="zoom-in-down" data-aos-duration="1100">
                        <Row>

                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className="revealx as_font14">
                                    <Form.Label>Appointment Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Preferred Date"
                                        name="apt_date"
                                        value={apt_date}
                                        className="form-control preferDate"
                                        onChange={(e) => setAptDate(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className="revealx as_font14">
                                    <Form.Label>Appointment Time</Form.Label>
                                    <Form.Control
                                        type="time"
                                        placeholder="Preferred Time"
                                        name="apt_time"
                                        value={apt_time}
                                        required
                                        className="form-control as_apt_timepicker"
                                        onChange={(e) => setAptTime(e.target.value)}
                                    />
                                    <span><img src="assets/images/svg/clock.svg" alt="" /></span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className=" revealx as_font14">
                                    <Form.Control type="text" name="apt_user_name" placeholder="Name" className="form-control" onKeyUp={(e) => e.target.value} required onChange={(e) => setAptUserName(e.target.value)} />
                                    <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className=" revealx as_font14">
                                    <PhoneInput type="text" placeholder="Phone Number" international name="apt_user_mobile" required onChange={(value) => setAptUserMobile(value)} />
                                    <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className=" revealx as_font14">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <Form.Control type="date" placeholder="Preferred Date" name="apt_user_dob" required className="form-control" onChange={(e) => setAptUserDob(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className=" revealx as_font14">
                                    <Form.Label>Time of Birth</Form.Label>
                                    <Form.Control type="time" placeholder=" apt_time" name="apt_user_tob" step={1} className="form-control as_apt_timepicker" onChange={(e) => setAptUserTob(e.target.value)} required />
                                    <span><img src="assets/images/svg/clock.svg" alt="" /></span>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className="revealx as_font14">
                                    <Form.Control
                                        type="email"
                                        name="apt_user_email"
                                        placeholder="Email"
                                        className="form-control"
                                        required
                                        onChange={(e) => validateEmail(e.target.value)}
                                    />
                                    <span><img src="assets/images/svg/mail1.svg" alt="" /></span>
                                </Form.Group>
                                <small id="emailError" className="text-danger" style={{ display: 'none' }}>
                                    Please enter a valid email address.
                                </small>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className=" revealx as_font14">
                                    <Form.Control type="text" name="apt_user_address" placeholder="City/Country" required className="form-control" onChange={(e) => setAptUserAddress(e.target.value)} />
                                    <span><img src="assets/images/svg/pin.svg" alt="" /></span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Label>Place of Birth Details</Form.Label>
                        </Form.Group>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className="revealx as_font14">
                                    <Form.Control
                                        type="text"
                                        name="apt_user_country"
                                        placeholder="Country"
                                        className="form-control"
                                        onChange={(e) => setAptUserCountry(e.target.value)}
                                        required
                                    />
                                    <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className="revealx as_font14">
                                    <Form.Control
                                        type="text"
                                        name="apt_user_state"
                                        placeholder="State"
                                        className="form-control"
                                        onChange={(e) => setAptUserState(e.target.value)}
                                        required
                                    />
                                    <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className="revealx as_font14">
                                    <Form.Control
                                        type="text"
                                        name="apt_user_dist"
                                        placeholder="District"
                                        className="form-control"
                                        onChange={(e) => setAptUserDist(e.target.value)}
                                        required
                                    />
                                    <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group className="revealx as_font14">
                                    <Form.Control
                                        type="text"
                                        name="appt_user_village"
                                        placeholder="Town/Village"
                                        className="form-control"
                                        onChange={(e) => setAptUserVillage(e.target.value)}
                                        required
                                    />
                                    <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="revealx as_font14" style={{ color: 'black' }}>
                            <Form.Group className="revealx as_font14">
                                <Form.Control
                                    type="hidden"
                                    placeholder="Place of Birth"
                                    name="apt_user_pob"
                                    className="form-control"
                                    value={apt_user_pob} // Dynamically updated value
                                    readOnly
                                />
                                <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group className=" revealx as_font14" style={{ color: 'black' }}>
                            <Form.Select className="form-control mb-3" name="apt_mode" aria-label="Consultation Mode" required onChange={(e) => setAptMode(e.target.value)}>
                                <option>Mode of Consultation</option>
                                <option value="Voice Call">Voice Call</option>
                                <option value="Video Call">Video Call</option>
                            </Form.Select>
                        </Form.Group>
                        {/* </Col>
                    <Col lg={6} md={6} sm={12}> */}
                        <Form.Group className="">
                            <div className="as_select_box as_font14_subhead">
                                <h4 data-aos="zoom-out-left" className='ms-3'>Select Services</h4>
                                <section className="ftco-section as_font14">
                                    <div className="row justify-content-center">
                                        <ul className="ks-cboxtags">
                                            <Row>
                                                <Col lg={6} md={6} sm={12}>
                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1000">
                                                        <Form.Check type="checkbox" id="checkboxOne" name="apt_services[]" value="ALPAstrology" label="ALP Astrology" onChange={handleCheckboxChange} />
                                                    </li>
                                                </Col>
                                                <Col lg={6} md={6} sm={12}>
                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1050">
                                                        <Form.Check type="checkbox" id="checkboxSix" name="apt_services[]" value="ALP Marriage Matching" label="ALP Marriage Matching" onChange={handleCheckboxChange} />
                                                    </li>
                                                </Col>
                                            </Row>
                                            <Row className=''>
                                                <Col lg={6} md={6} sm={12}>

                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1100">
                                                        <Form.Check type="checkbox" id="checkboxThree" name="apt_services[]" value="ALPNumerology" label="ALP Numerology" onChange={handleCheckboxChange} />
                                                    </li>
                                                </Col>
                                                <Col lg={6} md={6} sm={12}>
                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1150">
                                                        <Form.Check type="checkbox" id="checkboxFour" name="apt_services[]" value="ALPGemology" label="ALP Gemology" onChange={handleCheckboxChange} />
                                                    </li>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col lg={6} md={6} sm={12}>
                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1200">
                                                        <Form.Check type="checkbox" id="checkboxFive" name="apt_services[]" value="ALPPrasannam" label="ALP Prasannam" onChange={handleCheckboxChange} />
                                                    </li>
                                                </Col>
                                                <Col lg={6} md={6} sm={12}>
                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="125 0">
                                                        <Form.Check type="checkbox" id="checkboxTwo" name="apt_services[]" value="ALPVastu" label="ALP Vastu" onChange={handleCheckboxChange} />
                                                    </li>
                                                </Col>
                                            </Row>
                                        </ul>
                                    </div>
                                </section>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col lg={12} md={12} sm={12} className="as_font14_subhead">
                        <h4>Choose any 2 Astrologers</h4>
                        <Row>
                            {availableAstrologers.length > 0 ? (
                                availableAstrologers.map((astrologer, index) => (
                                    <Col lg='2' md='3' key={index} xs='5' className='m-1'>
                                        <div>
                                            <img
                                                src={astrologer.astrologers_image}
                                                alt={astrologer.astrologers_name}
                                                width={100} data-aos="fade-up"
                                                data-aos-duration="3000"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id={`astrologer_${astrologer.astrologers_id}`}
                                                name="asign_id[]"
                                                value={astrologer.user_id}
                                                checked={selectedAstrologers.includes(astrologer.astrologers_id)}
                                                onChange={() => handleAstrologerSelection(astrologer.astrologers_id)}
                                                disabled={!selectedAstrologers.includes(astrologer.astrologers_id) && selectedAstrologers.length >= 2}
                                                label={`ALP ${astrologer.astrologers_name}`}
                                                required
                                            />
                                        </div>
                                    </Col>
                                ))
                            ) : (
                                <p>No astrologers available.</p>
                            )}
                        </Row>
                        <p><b>Note :</b></p>
                        <ul>
                            <li>Consultation will ONLY be given by reputed ALP astrologers assigned by Dr. S. Pothuvudaimoorthy.</li>
                            <li>Dr. S. Pothuvudaimoorthy DOES NOT give any personalised consultation for any of these above mentioned appointments.</li>
                        </ul>
                    </Col>
                    <Col lg={12} className='my-1'>
                        <Form.Check type="checkbox" id="consentCheck1" name="s" value="I agree to all the above mentioned terms and conditions and hereby give my consent for taking ALP consultation services." required label="I agree to all the above mentioned terms and conditions and hereby give my consent for taking ALP consultation services." onChange={(e) => setAggreed(e.target.value)} />
                    </Col>
                    <Col lg={12} className='my-1'>
                        <Form.Check type="checkbox" id="consentCheck2" name="s" value="yes" onChange={(e) => setSignature(e.target.value)} required label="Signature of the client." />
                    </Col>
                    <Col lg={12} className='my-1'>
                        <Form.Check type="radio" id="acceptRadio" name="apt_req" value="Accept" onChange={(e) => setSignatureStatus(e.target.value)} required label="Accept" />
                        <Form.Check type="radio" id="denyRadio" name="apt_req" value="Deny" onChange={(e) => setSignatureStatus(e.target.value)} required label="Deny" />
                    </Col>
                    <Col lg={12} className="text-center">
                        <Button className="as_btn" type="submit" name="submit" disabled={isSubmitting}>Submit</Button>
                    </Col>
                    {/* <Button onClick={sendNotif}>Send</Button> */}
                </Row>
            </Form>
        </Container>
    );
};

export default AppointmentForm;
