import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { setLogLevel } from "firebase/app";

setLogLevel('debug');

const firebaseConfig = {
    apiKey: "AIzaSyAYqNhRpT6Ig2mhCpKLo0VSQSz6H7uAfyY",
    authDomain: "notification-d3fac.firebaseapp.com",
    projectId: "notification-d3fac",
    storageBucket: "notification-d3fac.appspot.com", // Fixed typo
    messagingSenderId: "601410710683",
    appId: "1:601410710683:web:691671fba81915317a2a3e",
    measurementId: "G-6T1J0VEBCP"
};

const vapidKey = "BEk0zuakXbsblow9bRMog5Hq4mQQBAVXpOgOHAtu1PcUEZTk7vX3XDQfAGPXW1fNTztyP7X8MZvJeuUwmMC_m34";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
    return new Promise((resolve, reject) => {
        console.log("Requesting User Permission...");
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                console.log("Notification Permission Granted.");
                getToken(messaging, { vapidKey })
                    .then((currentToken) => {
                        if (currentToken) {
                            // console.log("Client Token:", currentToken);
                            resolve(currentToken);
                        } else {
                            console.log("Failed to generate the app registration token.");
                            reject(new Error("Token generation failed"));
                        }
                    })
                    .catch((err) => {
                        console.error("Error fetching token:", err);
                        reject(err);
                    });
            } else {
                console.log("User Permission Denied.");
                reject(new Error("Permission denied"));
            }
        });
    });
};


requestPermission();

export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });
  
