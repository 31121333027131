import React, { useState, useEffect } from 'react';
import Header from './Common/Header'
import SideBar from './Common/SideBar'
import Footer from './Common/Footer'
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { requestPermission, onMessageListener } from "../firebase/firebase"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


function MakeAppt() {
    // const [data, setData] = useState([]);
    const [selectedAstrologers, setSelectedAstrologers] = useState([]);
    const [availableAstrologers, setAvailableAstrologers] = useState([]);
    const [apt_date, setAptDate] = useState('')
    const [apt_time, setAptTime] = useState('')
    const [apt_user_name, setAptUserName] = useState('')
    const [apt_user_mobile, setAptUserMobile] = useState('')
    const [apt_user_email, setAptUserEmail] = useState('')
    const [apt_user_address, setAptUserAddress] = useState('')
    const [apt_user_dob, setAptUserDob] = useState('')
    const [apt_user_tob, setAptUserTob] = useState('')
    const [apt_user_country, setAptUserCountry] = useState('')
    const [apt_user_state, setAptUserState] = useState('')
    const [apt_user_dist, setAptUserDist] = useState('')
    const [appt_user_village, setAptUserVillage] = useState('')
    const [apt_user_pob, setAptUserPob] = useState('')
    const [apt_mode, setAptMode] = useState('')
    const [formData, setFormData] = useState({
        apt_services: [],
    });
    const [appointmentFor, setAppointmentFor] = useState();
    const [aggreed, setAggreed] = useState()
    const [signature, setSignature] = useState()
    const [signatureStatus, setSignatureStatus] = useState()
    const [gender, setApptGender] = useState();
    const [addLifeIncident, setLifeIncidents] = useState();
    const [LifeIncidentDate, setLifeIncidentDate] = useState();
    // const [isSubmitting, setIsSubmitting] = useState(false); // Add loading state
    const navigate = useNavigate();

    const [deviceToken, setDeviceToken] = useState(null);

    useEffect(() => {
        const MAX_RETRIES = 15; // Maximum retries for fetching token
        let retryCount = 0; // Track retry attempts

        const fetchToken = () => {
            requestPermission()
                .then((token) => {
                    if (token) {
                        setDeviceToken(token); // Update state
                        localStorage.setItem("deviceToken", token); // Save to localStorage
                        console.log("FCM Token successfully retrieved:", token);
                    } else if (retryCount < MAX_RETRIES) {
                        retryCount += 1;
                        console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
                        setTimeout(fetchToken, 1000); // Retry after 1 second
                    } else {
                        console.error("Failed to fetch device token after retries.");
                    }
                })
                .catch((err) => {
                    console.error("Error retrieving FCM Token:", err);
                    if (retryCount < MAX_RETRIES) {
                        retryCount += 1;
                        console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
                        setTimeout(fetchToken, 1000); // Retry after 1 second
                    }
                });
        };

        // Initial token fetch
        fetchToken();

        // Listen for foreground messages
        onMessageListener()
            .then((payload) => {
                console.log("Message received in foreground:", payload);
                new Notification(payload.notification.title, {
                    body: payload.notification.body,
                });
            })
            .catch((err) => {
                console.error("Error listening to foreground messages:", err);
            });
    }, []);
    useEffect(() => {
        // Get the current date and time in IST
        const currentDate = new Date().toLocaleDateString('en-GB', {
            timeZone: 'Asia/Kolkata',
        });
        const currentTime = new Date().toLocaleTimeString('en-GB', {
            hour12: false,
            timeZone: 'Asia/Kolkata',
        });

        // Format the date to YYYY-MM-DD and time to HH:MM
        const formattedDate = currentDate.split('/').reverse().join('-'); // Converts DD/MM/YYYY to YYYY-MM-DD
        const formattedTime = currentTime.substring(0, 5); // Extract HH:MM from HH:MM:SS

        setAptDate(formattedDate);
        setAptTime(formattedTime);
    }, []);


    useEffect(() => {
        const apt_email = localStorage.getItem('userEmail');
        setAptUserEmail(apt_email)
        console.log('user Email', apt_user_email)
    }, [apt_user_email])
    const fetchAvailableAstrologer = () => {
        if (!apt_date || !apt_time) {
            console.error("Both date and time are required to fetch astrologers.");
            return;
        }

        // Prepare FormData
        const formDataToSend = new FormData();
        formDataToSend.append('date', apt_date);
        formDataToSend.append('time', apt_time);

        fetch('https://alpastrology.online/alpapi/User_api/get_available_astrologer', {
            method: 'POST',
            headers: {
                'x-api-key': 'alp@123',
            },
            body: formDataToSend,
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log('Fetched available astrologers:', responseData);
                if (responseData.status === "Success" && Array.isArray(responseData.data)) {
                    setAvailableAstrologers(responseData.data); // Update state with astrologers
                } else {
                    console.error('Unexpected response format:', responseData);
                    setAvailableAstrologers([]); // Clear astrologers if data is not as expected
                }
            })
            .catch((error) => {
                console.error('Error fetching available astrologers:', error);
                setAvailableAstrologers([]); // Clear astrologers on error
            });
    };

    useEffect(() => {
        if (apt_date && apt_time) {
            fetchAvailableAstrologer();
        }
    }, [apt_date, apt_time]);

    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === 'apt_services[]') {
            if (checked) {
                setFormData({ ...formData, apt_services: [...formData.apt_services, value] });
            } else {
                setFormData({ ...formData, apt_services: formData.apt_services.filter(service => service !== value) });
            }
        }
    };

    const handleAstrologerSelection = (userId) => {
        setSelectedAstrologers(prevSelected => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter(id => id !== userId);
            } else if (prevSelected.length < 2) {
                return [...prevSelected, userId];
            }
            return prevSelected;
        });
    };


    useEffect(() => {
        const placeOfBirth = `${appt_user_village || ''}, ${apt_user_dist || ''}, ${apt_user_state || ''}, ${apt_user_country || ''}`.replace(/(^, )|((, )+$)/g, ''); // Handle empty values
        setAptUserPob(placeOfBirth);
    }, [apt_user_country, apt_user_state, apt_user_dist, appt_user_village]);

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        // Validate required fields
        if (!apt_date || !apt_time || !apt_user_name || !apt_user_mobile || !apt_user_email || !apt_mode) {
            alert("Please fill in all required fields.");
            return;
        }

        if (selectedAstrologers.length === 0) {
            alert("Please select at least one astrologer.");
            return;
        }
        if (signatureStatus !== "Accept") {
            alert("Please accept the terms before submitting.");
            return;
        }

        const form = new FormData();
        form.append('apt_date', apt_date);
        form.append('apt_time', apt_time);
        form.append('apt_user_name', apt_user_name);
        form.append('apt_user_mobile', apt_user_mobile);
        form.append('apt_user_email', apt_user_email);
        form.append('apt_user_address', apt_user_address);
        form.append('apt_user_dob', apt_user_dob);
        form.append('apt_user_tob', apt_user_tob);
        form.append('apt_user_pob', apt_user_pob);
        form.append('apt_mode', apt_mode);
        form.append('user_device_token', deviceToken)
        form.append('aggreed', aggreed)
        form.append('signature', signature)
        form.append('appt_for', appointmentFor)
        form.append('signature_status', signatureStatus)
        form.append('gender', gender)
        form.append('life_incidents', addLifeIncident)
        form.append('life_incident_date', LifeIncidentDate)

        // Ensure apt_services is an array
        formData.apt_services.forEach(service => {
            form.append('apt_services[]', service);
        });

        selectedAstrologers.forEach(astrologer => {
            form.append('asign_id[]', astrologer);
        });

        console.log('Form Data to be submitted:', form); // Log the form data

        try {
            const response = await fetch('https://alpastrology.online/alpapi/User_api/appointment', {
                method: 'POST',
                headers: {
                    'x-api-key': 'alp@123',
                },
                body: form,
            });

            const text = await response.text();
            console.log('Response text:', text);

            try {
                const responseData = JSON.parse(text);
                console.log('Form submission response:', responseData);

                if (responseData.status === "Success") {

                    const notifData = new FormData();
                    notifData.append('title', 'ALP Online');
                    notifData.append('message', apt_user_name + ' Sent a new Appointment request');

                    axios.post(
                        'https://alpastrology.online/alpapi/User_api/notification_send',
                        notifData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'x-api-key': 'alp@123',
                            },
                        }
                    )


                    alert('Appointment booked successfully!');
                    window.location.reload();
                    setFormData({
                        apt_services: [],
                    });
                    setAptUserName('')
                    setSelectedAstrologers([]);
                    navigate('/MyAppointments')
                } else {
                    alert('Error submitting form. Please try again.');
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
                alert('Error parsing response. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form. Please try again.');
        }
    };


    return (


        <>
            <Header />
            <SideBar />
            <div className="app-wrapper">

                <div className="app-content pt-3 p-md-3 p-lg-4">
                    <div className="container-xl ">
                        <div className="wl_header">
                            <h3>Make an Appointments</h3>
                        </div>
                        <Form className="as_appointment_form" method="post" action="" onSubmit={handleSubmit}>
                            <Row>
                                <Col lg={6} md={6} sm={12} className="as_font14_subhead" data-aos="zoom-in-down" data-aos-duration="1100">
                                    <Row>

                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Label>Appointment Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Preferred Date"
                                                    name="apt_date"
                                                    value={apt_date}
                                                    className="form-control preferDate"
                                                    onChange={(e) => setAptDate(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Label>Appointment Time</Form.Label>
                                                <Form.Control
                                                    type="time"
                                                    placeholder="Preferred Time"
                                                    name="apt_time"
                                                    value={apt_time}
                                                    className="form-control as_apt_timepicker"
                                                    onChange={(e) => setAptTime(e.target.value)}
                                                />
                                                <span><img src="assets/images/svg/clock.svg" alt="" required /></span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" name="apt_user_name" placeholder="" className="form-control" onKeyUp={(e) => e.target.value = e.target.value.replace(/[^A-z]/g, '')} required onChange={(e) => setAptUserName(e.target.value)} />
                                                <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14">
                                                <Form.Label>Mobile</Form.Label>
                                                <PhoneInput type="text" international placeholder="" name="apt_user_mobile" required onChange={(value) => setAptUserMobile(value)} />
                                                <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14">

                                                <Form.Label>Date of Birth</Form.Label>
                                                <Form.Control type="date" placeholder="Preferred Date" name="apt_user_dob" className="form-control" onChange={(e) => setAptUserDob(e.target.value)} required />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14">
                                                <Form.Label>Time of Birth</Form.Label>
                                                <Form.Control type="time" placeholder=" apt_time" name="apt_user_tob" step={1} className="form-control as_apt_timepicker" onChange={(e) => setAptUserTob(e.target.value)} />
                                                <span><img src="assets/images/svg/clock.svg" alt="" required /></span>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14">
                                                <Form.Label>City/Country</Form.Label>
                                                <Form.Control type="text" name="apt_user_address" placeholder="" className="form-control" onChange={(e) => setAptUserAddress(e.target.value)} />
                                                <span><img src="assets/images/svg/pin.svg" alt="" required /></span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14" style={{ color: 'black' }}>
                                                <Form.Label>Consultation Mode</Form.Label>
                                                <Form.Select className="form-control mb-3" name="apt_mode" required aria-label="" onChange={(e) => setAptMode(e.target.value)}>
                                                    <option>Select One</option>
                                                    <option value="Voice Call">Voice Call</option>
                                                    <option value="Video Call">Video Call</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14" style={{ color: 'black' }}>
                                                <Form.Label>Gender</Form.Label>
                                                <Form.Select className="form-control mb-3" name="apt_gender" required aria-label="" onChange={(e) => setApptGender(e.target.value)}>
                                                    <option value=''>Select</option>
                                                    <option value='Male'>Male</option>
                                                    <option value='Female'>Female</option>
                                                    <option value='Other'>Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14" style={{ color: 'black' }}>
                                                <Form.Label>Appointment For</Form.Label>
                                                <Form.Select className="form-control mb-3" name="apt_for" required aria-label="" onChange={(e) => setAppointmentFor(e.target.value)}>
                                                    <option>Select One</option>
                                                    <option value='Self'>Self</option>
                                                    <option value='Wife'>Wife</option>
                                                    <option value='Children'>Children</option>
                                                    <option value='Mother'>Mother</option>
                                                    <option value='Father'>Father</option>
                                                    <option value='Brother'>Brother</option>
                                                    <option value='Sister'>Sister</option>
                                                    <option value='Others'>Others</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14" style={{ color: 'black' }}>
                                                <Form.Label>Add Life Incidents For</Form.Label>
                                                <Form.Select className="form-control mb-3" name="AddlifeIncident" required aria-label="" onChange={(e) => setLifeIncidents(e.target.value)}>
                                                    <option>Select One</option>
                                                    <option value='Marriage Date'>Marriage Date</option>
                                                    <option value='Child Born Date'>Child Born Date</option>
                                                    <option value='2 Wheeler Bought Date'>2 Wheeler Bought Date</option>
                                                    <option value='4 Wheeler Bought Date'>4 Wheeler Bought Date</option>
                                                    <option value='House Constructed Date'>House Constructed Date</option>
                                                    <option value='Land Purchase Date'>Land Purchase Date</option>
                                                    <option value='Mobile Purchase Date'>Mobile Purchase Date</option>
                                                    <option value='Job Joined Date'>Job Joined Date</option>
                                                    {
                                                        gender === 'Female' && (

                                                            <option value='Age Attend Date'>Age Attend Date</option>
                                                        )
                                                    }

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14">
                                                <Form.Label>Life Inceident Date</Form.Label>
                                                <Form.Control type="date" name="life_incidentdate" placeholder="" className="form-control" onChange={(e) => setLifeIncidentDate(e.target.value)} />
                                                <span><img src="assets/images/svg/pin.svg" alt="" required /></span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group>
                                        <Form.Label>Place of Birth Details</Form.Label>
                                    </Form.Group>
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="apt_user_country"
                                                    placeholder=""
                                                    className="form-control"
                                                    onChange={(e) => setAptUserCountry(e.target.value)}
                                                    required
                                                />
                                                <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="apt_user_state"
                                                    placeholder=""
                                                    className="form-control"
                                                    onChange={(e) => setAptUserState(e.target.value)}
                                                    required
                                                />
                                                <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Label>District</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="apt_user_dist"
                                                    placeholder=""
                                                    className="form-control"
                                                    onChange={(e) => setAptUserDist(e.target.value)}
                                                    required
                                                />
                                                <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Label>Town/Village</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="appt_user_village"
                                                    placeholder=""
                                                    className="form-control"
                                                    onChange={(e) => setAptUserVillage(e.target.value)}
                                                    required
                                                />
                                                <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group className="revealx as_font14" style={{ color: 'black' }}>
                                        <Form.Group className="revealx as_font14">
                                            <Form.Control
                                                type="hidden"
                                                placeholder="Place of Birth"
                                                name="apt_user_pob"
                                                className="form-control"
                                                value={apt_user_pob} // Dynamically updated value
                                                readOnly
                                            />
                                            <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                        </Form.Group>
                                    </Form.Group>



                                    <Form.Group className="">
                                        <div className="as_select_box as_font14_subhead">
                                            <h4 data-aos="zoom-out-left" className='ms-3'>Select Services</h4>
                                            <section className="ftco-section as_font14">
                                                <div className="row justify-content-center">
                                                    <ul className="ks-cboxtags">
                                                        <Row>
                                                            <Col lg={6} md={6} sm={12}>
                                                                <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1000">
                                                                    <Form.Check type="checkbox" id="checkboxOne" name="apt_services[]" value="ALPAstrology" label="ALP Astrology" onChange={handleCheckboxChange} />
                                                                </li>
                                                            </Col>
                                                            <Col lg={6} md={6} sm={12}>
                                                                <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1050">
                                                                    <Form.Check type="checkbox" id="checkboxSix" name="apt_services[]" value="ALP Marriage Matching" label="ALP Marriage Matching" onChange={handleCheckboxChange} />
                                                                </li>
                                                            </Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col lg={6} md={6} sm={12}>

                                                                <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1100">
                                                                    <Form.Check type="checkbox" id="checkboxThree" name="apt_services[]" value="ALPNumerology" label="ALP Numerology" onChange={handleCheckboxChange} />
                                                                </li>
                                                            </Col>
                                                            <Col lg={6} md={6} sm={12}>
                                                                <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1150">
                                                                    <Form.Check type="checkbox" id="checkboxFour" name="apt_services[]" value="ALPGemology" label="ALP Gemology" onChange={handleCheckboxChange} />
                                                                </li>
                                                            </Col>
                                                        </Row>
                                                        <Row >
                                                            <Col lg={6} md={6} sm={12}>
                                                                <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1200">
                                                                    <Form.Check type="checkbox" id="checkboxFive" name="apt_services[]" value="ALPPrasannam" label="ALP Prasannam" onChange={handleCheckboxChange} />
                                                                </li>
                                                            </Col>
                                                            <Col lg={6} md={6} sm={12}>
                                                                <li className="revealx" data-aos="zoom-out-left" data-aos-duration="125 0">
                                                                    <Form.Check type="checkbox" id="checkboxTwo" name="apt_services[]" value="ALPVastu" label="ALP Vastu" onChange={handleCheckboxChange} />
                                                                </li>
                                                            </Col>
                                                        </Row>
                                                    </ul>
                                                </div>
                                            </section>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg={12} md={12} sm={12} className="as_font14_subhead">
                                    {apt_date ? (
                                        <>
                                            <h4>Choose any 2 Astrologers</h4>
                                            <Row>
                                                {availableAstrologers.length > 0 ? (
                                                    availableAstrologers.map((astrologer, index) => (
                                                        <Col lg='2' md='3' key={index} xs='5' className='m-1'>
                                                            <div>
                                                                <img
                                                                    src={astrologer.astrologers_image}
                                                                    alt={astrologer.astrologers_name}
                                                                    width={100} data-aos="fade-up"
                                                                    data-aos-duration="3000"
                                                                />
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`astrologer_${astrologer.astrologers_id}`}
                                                                    name="asign_id[]"
                                                                    value={astrologer.user_id}
                                                                    checked={selectedAstrologers.includes(astrologer.astrologers_id)}
                                                                    onChange={() => handleAstrologerSelection(astrologer.astrologers_id)}
                                                                    disabled={!selectedAstrologers.includes(astrologer.astrologers_id) && selectedAstrologers.length >= 2}
                                                                    label={astrologer.astrologers_name}
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>
                                                    ))
                                                ) : (
                                                    <p>No astrologers available.</p>
                                                )}
                                            </Row>
                                        </>

                                    ) : null}
                                    <p><b>Note :</b></p>
                                    <ul>
                                        <li>Consultation will ONLY be given by reputed ALP astrologers assigned by Dr. S. Pothuvudaimoorthy.</li>
                                        <li>Dr. S. Pothuvudaimoorthy DOES NOT give any personalised consultation for any of these above mentioned appointments.</li>
                                    </ul>
                                </Col>
                                <Col lg={12} className='my-1'>
                                    <Form.Check type="checkbox" id="consentCheck1" name="s" value="I agree to all the above mentioned terms and conditions and hereby give my consent for taking ALP consultation services." required label="I agree to all the above mentioned terms and conditions and hereby give my consent for taking ALP consultation services." onChange={(e) => setAggreed(e.target.value)} />
                                </Col>
                                <Col lg={12} className='my-1'>
                                    <Form.Check type="checkbox" id="consentCheck2" name="s" value="yes" onChange={(e) => setSignature(e.target.value)} required label="Signature of the client." />
                                </Col>
                                <Col lg={12} className='my-1'>
                                    <Form.Check type="radio" id="acceptRadio" name="apt_req" value="Accept" onChange={(e) => setSignatureStatus(e.target.value)} required label="Accept" />
                                    <Form.Check type="radio" id="denyRadio" name="apt_req" value="Deny" onChange={(e) => setSignatureStatus(e.target.value)} required label="Deny" />
                                </Col>
                                <Col lg={12} className="text-center">
                                    <Button className="as_btn" type="submit" name="submit">Submit</Button>
                                </Col>
                                {/* <Button onClick={sendNotif}>Send</Button> */}
                            </Row>
                        </Form>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MakeAppt