import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
// import { IoMdRadioButtonOn } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { IoMail } from "react-icons/io5";
// import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import { AiOutlineArrowUp } from "react-icons/ai";

function AppointmentForm() {
    return (
        <>
            <Container fluid className='footer py-5'>
                <Container>
                    <Row>
                        <Col lg={4}>
                            <Link to='/AstroLogin'>
                                <img src='../assets/logo.png' alt="Logo" width="200px" />
                            </Link>

                            <p className='text-white'><b>ALP Astrology: Revealing Fundamentals and navigating towards a Brighter Future</b></p>
                            <ul className='text-white d-flex col-lg-8'>
                                <li className="fs-3 mx-auto">
                                    <a href="https://www.facebook.com/astrologer.moorthy.pattukkottai/" className='social-media-icons' target="_blank" rel="noopener noreferrer">
                                        <FaFacebook />
                                    </a>
                                </li>
                                <li className="fs-3 mx-auto">
                                    <a href="https://www.youtube.com/sastitv" className='social-media-icons' target="_blank" rel="noopener noreferrer">
                                       <FaYoutube />
                                    </a>
                                </li>
                                <li className="fs-3 mx-auto">
                                    <a href="https://www.instagram.com/alp_astrology/" className='social-media-icons' target="_blank" rel="noopener noreferrer">
                                       <FaInstagramSquare />
                                    </a>
                                </li>
                                <li className="fs-3 mx-auto">
                                    <a href="https://x.com/alpastrologer" className='social-media-icons' target="_blank" rel="noopener noreferrer">
                                        <FaTwitter />
                                    </a>
                                </li>
                            </ul>
                        </Col>

                        <Col lg={4} className='mt-5 pt-5'>
                            <p className='text-white fs-3'>LOCATION</p>
                            <ul className='text-white'>
                                <li className='fs-6 mx-auto'><FaLocationDot className='me-3' /><br />F 2, 1st Floor, Shiva Homes, Jayalakshmi Nagar, Anandha Sayanam, Moulivakkam, Chennai, TamilNadu, India, Pincode: 600116.</li>
                                <li className='fs-6 mx-auto'><FaLocationDot className='me-3' />
                                    <br /><b>S.POTHUVUDAIMOORTHY</b><br />
                                    <b>ALP Illam</b><br />
                                    No.18A Ganesh Nagar(First Right), Old Perungalathur(Near Lotus Pond), S.V Nagar Post, Chennai 6000063</li>
                            </ul>

                        </Col>
                        <Col lg='3' className='mt-5 pt-5'>
                            <p className='text-white fs-3'>CONTACT</p>
                            <ul className='text-white'>
                                <li className='fs-6 mx-auto'><IoCall />+ 91 9786556156</li>
                                <li className='fs-6 mx-auto'><IoCall />+ 91 8000115656</li>
                                <li className='fs-6 mx-auto'><IoMail />alpastrologyoffice@gmail.com</li>
                                <li className='fs-6 mx-auto'><IoMail />alpastrology.org@gmail.com</li>
                            </ul>
                        </Col>

                    </Row>
                </Container>
            </Container>
            <ScrollToTop
                smooth
                style={{
                    backgroundColor: '#FF6E42',
                    borderRadius: '50%',
                    width: '58px',
                    height: '58px',
                    display: 'flex',
                    marginBottom: '85px',
                    marginRight: '-10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '28px',
                    zIndex:'1000'
                }}
                component={<AiOutlineArrowUp style={{ color: 'white' }} />}
            />
        </>
    );
}

export default AppointmentForm;