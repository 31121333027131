import React from 'react';
// import bg_video from '../assets/video/mainbg_video.mp4';
// import star from '../assets/star.png';
import { Col, Container, Row } from 'react-bootstrap';
import '../css/slider.css'

const MainBanner = () => {
  return (
    <div className="body-data">
      {/* <Appbar /> */}
      <Row>
        <Container fluid >
          <Row>
            <div className='sbody'>

              <div className="slider">
                <input name="input-slider" id="input-slide-0" type="radio" className="input-slide sinput input-slide-num" />
                <input name="input-slider" id="input-slide-1" type="radio" className="input-slide sinput input-slide-num" />
                <input name="input-slider" id="input-slide-2" type="radio" className="input-slide sinput input-slide-num" />
                <input name="input-slider" id="input-slide-3" type="radio" className="input-slide sinput input-slide-num" />
                <input name="input-slider" id="input-slide-autoplay" type="radio" className="input-slide sinput input-slide-num" defaultChecked />

                <ul className='sul'>
                  <li className="slide-0 sli">
                    <img src="https://alpastrology.com/assets/imgs/gallery1.JPG" alt="Slide 1" />
                  </li>
                  <li className="slide-1 sli">
                    <img src="https://alpastrology.com/assets/imgs/gallery7.JPG" alt="Slide 2" />
                  </li>
                  <li className="slide-2 sli">
                    <img src="https://alpastrology.com/assets/imgs/gallery8.JPG" alt="Slide 3" />
                  </li>
                  <li className="slide-3 sli">
                    <img src="https://alpastrology.com/assets/imgs/gallery15.JPG" alt="Slide 4" />
                  </li>
                </ul>



                <div className="slider-arrow-prev">
                  <label className="slabel slide-0" htmlFor="input-slide-0"></label>
                  <label className="slabel slide-1" htmlFor="input-slide-1"></label>
                  <label className="slabel slide-2" htmlFor="input-slide-2"></label>
                  <label className="slabel slide-3" htmlFor="input-slide-3"></label>
                </div>

                <div className="slider-arrow-next">
                  <label className="slabel slide-0" htmlFor="input-slide-0"></label>
                  <label className="slabel slide-1" htmlFor="input-slide-1"></label>
                  <label className="slabel slide-2" htmlFor="input-slide-2"></label>
                  <label className="slabel slide-3" htmlFor="input-slide-3"></label>
                </div>

                <div className="slider-dot">
                  <label className="slabel slide-0" htmlFor="input-slide-0"></label>
                  <label className="slabel slide-1" htmlFor="input-slide-1"></label>
                  <label className="slabel slide-2" htmlFor="input-slide-2"></label>
                  <label className="slabel slide-3" htmlFor="input-slide-3"></label>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Row>
      <Container fluid className="second-banner">
        <Container>
          <div className="terms_consultation">
            <Row>
              <p className="display-5 my-3 py-3 fw-bold mt-2 text-center" data-aos="zoom-in-down" >Terms & Conditions</p>
              <Col>
                <p className="fw-bold" data-aos="zoom-out">Please read the following rules and regulations carefully before booking your appointment and provide your consent by accepting the document below.</p>
                <div className="terms-details">
                  <ul className="terms-details-ol">
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Consultation charges will be based on the services selected by the client.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Appointment confirmation will be sent from the ALP office to your respective WhatsApp phone number.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Priority based Consultation will be provided for clients based on the urgency of the situation, provided priority appointment slots are available at our end.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Consultation will be provided by reputed ALP Astrologers.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Questions pertaining to longevity and life span is strictly not entertained.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Questions pertaining to determination of sex of baby during pregnancy is strictly not entertained.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >All predictions are completely based on the birth details and information provided by the client.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >We do not guarantee accuracy of predictions, as it may vary, if the information provided by the client is incorrect or inadequate.</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default MainBanner;
