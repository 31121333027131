import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import logo from '../../assets/images/logo-removebg-preview.png';
import { FaHome, FaFacebookMessenger, FaUsers } from "react-icons/fa";
import { BiMessageAltError } from "react-icons/bi";
import { HiOutlineLogout } from "react-icons/hi";
import { ImBooks } from "react-icons/im";
import { GrNotes } from "react-icons/gr";
import { BiMessageAdd } from "react-icons/bi";
import { TiDocumentAdd } from "react-icons/ti";


function SideBar() {
    const navigate = useNavigate();
    const location = useLocation();
    // const [waitList, setWaitList] = useState(0);
    // const [Admintype, setAdminType] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar visibility state
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const isActive = (path) => location.pathname === path;

    useEffect(() => {
        const isLogged = localStorage.getItem('loggedIn');
        if (!isLogged) {
            navigate('/LoginScreen', { replace: true });
        }
    })

    const handleLogout = () => {
        setTimeout(() => {
            localStorage.clear();
            navigate("/LoginScreen", { replace: true });
            console.log('logout button clicked');
        }, 30);
    }

    return (
        <>
            <div className="col-auto">
                <a
                    id="sidepanel-toggler"
                    className="sidepanel-toggler d-inline-block d-xl-none"
                    href="#"
                    onClick={toggleSidebar} // Toggle the sidebar on click
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img">
                        <title>Menu</title>
                        <path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M4 7h22M4 15h22M4 23h22"></path>
                    </svg>
                </a>
            </div>
            <div id="app-sidepanel" className={`app-sidepanel ${isSidebarOpen ? 'active' : ''}`}>
                <div id="sidepanel-drop" className="sidepanel-drop"></div>
                <div className="sidepanel-inner d-flex flex-column">
                    <a
                        href="#"
                        id="sidepanel-close"
                        className="sidepanel-close d-xl-none"
                        onClick={toggleSidebar} // Close the sidebar on click
                    >
                        &times;
                    </a>
                    <div className="app-branding">
                        <Link className="app-logo" to="">
                            <img className="logo-icon me-2" src={logo} alt="logo" />
                            <span className="logo-text">ALP Astrology</span>
                        </Link>
                    </div>

                    <nav id="app-nav-main" className="app-nav app-nav-main flex-grow-1">
                        <ul className="app-menu list-unstyled accordion" id="menu-accordion">
                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/Dashboard') ? 'active' : ''}`} to="/Dashboard">
                                    <span className="nav-icon">
                                        <FaHome />
                                    </span>
                                    <span className="nav-link-text">Dashboard</span>
                                </Link>
                            </li>


                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/MyProfile') ? 'active' : ''}`} to="/MyProfile">
                                    <span className="nav-icon">
                                        <FaUsers />
                                    </span>
                                    <span className="nav-link-text">My Profile</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className={`nav-link ${isActive('/waitingMessages') ? 'active' : ''} wait`} to="/waitingMessages">
                                    <span className="icon-badge">{waitList}</span>
                                    <span className="nav-icon">
                                        <BiMessageAltError />
                                    </span>
                                    <span className="nav-link-text">Waiting Messages</span>
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/MakeMessage') ? 'active' : ''}`} to="/MakeMessage">
                                    <span className="nav-icon">
                                        <BiMessageAdd  />
                                    </span>
                                    <span className="nav-link-text">ALP Horoscope Chart</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/Mymessage') ? 'active' : ''}`} to="/Mymessage">
                                    <span className="nav-icon">
                                        <FaFacebookMessenger />
                                    </span>
                                    <span className="nav-link-text">My Request</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/MakeAppointment') ? 'active' : ''}`} to="/MakeAppointment">
                                    <span className="nav-icon">
                                        <TiDocumentAdd />
                                    </span>
                                    <span className="nav-link-text">Make an Appointments</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/MyAppointments') ? 'active' : ''}`} to="/MyAppointments">
                                    <span className="nav-icon">
                                        <GrNotes />
                                    </span>
                                    <span className="nav-link-text">My Appointments</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link className={`nav-link ${isActive('/Classes') ? 'active' : ''}`} to='/Classes'>
                                    <span className='nav-icon'>
                                        <ImBooks />
                                    </span>
                                    <span className="nav-link-text">Classes</span>
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" onClick={handleLogout}>
                                    <span className="nav-icon">
                                        <HiOutlineLogout />
                                    </span>
                                    <span className="nav-link-text">Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>

                    <div className="app-sidepanel-footer">
                        <nav className="app-nav app-nav-footer">
                            <ul className="app-menu footer-menu list-unstyled">

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideBar