import React, { useEffect, useState } from 'react'
import Appbar from './AppBar'
import Footer from './Footer'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { FaCalendarAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import Chatbot from './Chatbot';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const Courses = () => {
  const [upCommingCourse, setUpCommingCOurse] = useState([])
  const [recentCourse, setRecentCourse] = useState([])
  const [enquiry_name, setEnquiryName] = useState('')
  const [enquiry_email, setEnquiryEmail] = useState('')
  const [enquiry_mobile, setEnquiryMobile] = useState('')
  const [enquiry_course, setEnquiryCourse] = useState('')
  const [enquiry_address, setEnquiryAddress] = useState('')
  const [course_lang, setCourseLang] = useState('')
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailError, setEmailError] = useState(false)

  useEffect(() => {
    const fetchUpcomminCourse = () => {
      axios.post(`https://alpastrology.online/alpapi/User_api/upcomming_course`, {}, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': 'alp@123'
        }
      }).then((response) => {
        if (response.data.status === 'Success') {
          setUpCommingCOurse(response.data.data)
        } else {
          setUpCommingCOurse([]);
        }
      }).catch((error) => {
        console.error('failed to fetch up comming course:', error)
      })
    }
    fetchUpcomminCourse();
  })
  useEffect(() => {
    const fetchrecentCourse = () => {
      axios.post(`https://alpastrology.online/alpapi/User_api/recent_course`, {}, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': 'alp@123'
        }
      }).then((response) => {
        if (response.data.status === 'Success') {
          setRecentCourse(response.data.data)
        } else {
          setRecentCourse([]);
        }
      }).catch((error) => {
        console.error('failed to fetch recent course:', error)
      })
    }
    fetchrecentCourse();
  })

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|online|org|net|edu|gov|info|io|co|in)$/;
    if (emailRegex.test(email)) {
      setEnquiryEmail(email);
      setEmailError(false); // No error
      return true;  // Email

    } else {
      setEmailError(true); // Show error
      return false;  // Email

    }
  }

  const handleAddEnquiry = (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(enquiry_email);
    if (!isValidEmail) {
      setIsSubmitting(false)
      return;
    }
    setIsSubmitting(true)
    const formData = new FormData();
    formData.append('enquiry_name', enquiry_name);
    formData.append('enquiry_email', enquiry_email);
    formData.append('enquiry_mobile', enquiry_mobile);
    formData.append('enquiry_course', enquiry_course);
    formData.append('enquiry_address', enquiry_address);
    formData.append('course_lang', course_lang);

    axios
      .post('https://alpastrology.online/alpapi/User_api/course_enquiry', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': 'alp@123',
        },
      })
      .then((response) => {
        if (response.data.status === 'Success') {
          alert('Enquiry sent successfully');
          navigate('/signin');
          setEnquiryName('');
          setEnquiryEmail('');
          setEnquiryMobile('');
          setEnquiryCourse('');
          setEnquiryAddress('');
        } else {
          alert('Enquiry submission failed');
        }
      })
      .catch((error) => {
        console.error('Error sending enquiry:', error);
      });
  };


  const convertTo12Hour = (time24) => {
    // Handle undefined, null, or invalid inputs
    if (!time24 || typeof time24 !== 'string') {
      // console.warn('Invalid time value:', time24); // Debugging
      return 'Invalid Time';
    }

    const [hours, minutes] = time24.split(':').map(Number);

    // Validate split values
    if (isNaN(hours) || isNaN(minutes)) {
      console.warn('Malformed time value:', time24); // Debugging
      return 'Invalid Time';
    }

    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  };


  return (
    <>
      <Appbar />
      {/* <div className='Courses_page'>
        <div className="overlay"></div>
        <div className="title" >
          <h1 className="display-1 fw-bold consultation_title" data-aos="fade-down-right" data-aos-duration="1000">Courses</h1>
          <div className="nav-home">
            <ul className="home_cons" data-aos="flip-left" data-aos-duration="1000">
              <li className="mx-3"><a href="/" className="text-white">Home</a></li>
              <li className="mx-3"><img src='../assets/star.png' alt="Star" /></li>
              <li className="mx-3">Courses</li>
            </ul>
          </div>
        </div>
      </div> */}
      <Container>
        <Row>
          <Col lg={4} md={6} sm={12} className='my-3' >
            <Card className='course_card'>
              <Card.Header className='fw-bold text-center bg-info fs-4'>Class Hours IST</Card.Header>
              <Card.Body className='card_course_body'>

                <Card.Text>
                  <Table striped bordered hover variant="light" responsive>
                    <tbody>
                      <tr>
                        <th>Monday</th>
                        <td>04:32AM - 06:32AM</td>
                      </tr>
                      <tr>
                        <th>Tuesday</th>
                        <td>04:32AM - 06:32AM</td>
                      </tr>
                      <tr>
                        <th>Wednesday</th>
                        <td>04:32AM - 06:32AM</td>
                      </tr>
                      <tr>
                        <th>Thursday</th>
                        <td>04:32AM - 06:32AM</td>
                      </tr>
                      <tr>
                        <th>Friday</th>
                        <td>04:32AM - 06:32AM</td>
                      </tr>
                      <tr>
                        <th>Saturday</th>
                        <td>04:32AM - 06:32AM</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} className='my-3' >
            <Card className='course_card'>
              <Card.Header className='fw-bold text-center bg-info fs-4'>Up Coming Courses</Card.Header>
              <Card.Body className='card_course_body'>
                {/* <Card.Title>Special title treatment</Card.Title> */}
                {upCommingCourse.length > 0 ? (
                  upCommingCourse.map((upcomming, index) => {
                    const date = new Date(upcomming.date).toLocaleDateString('en-GB');

                    // console.log('Start Time:', upcomming.starttime); // Debugging
                    // console.log('End Time:', upcomming.endtime);   // Debugging

                    const startTime12 = convertTo12Hour(upcomming.starttime);
                    const endTime12 = convertTo12Hour(upcomming.endtime);

                    return (
                      <Card.Text key={index}>
                        <FaCalendarAlt className="mx-3" />
                        <p>
                          {upcomming.class_title} - {upcomming.class_type} class
                        </p>
                        <p>
                          Language:{upcomming.class_language}
                        </p>
                        <p>Date:{date} - {startTime12} to {endTime12}</p>
                        <p>Session: {upcomming.class_mode}</p>
                      </Card.Text>
                    );
                  })
                ) : (
                  <Card.Text>
                    No course
                  </Card.Text>
                )}

              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} className='my-3' >
            <Card className='course_card'>
              <Card.Header className='fw-bold text-center bg-info fs-4'>Recent Courses</Card.Header>
              <Card.Body className='card_course_body'>
                {recentCourse.length > 0 ? (
                  recentCourse.map((recent, index) => {
                    const date = new Date(recent.date).toLocaleDateString('en-GB');
                    const startTime12 = convertTo12Hour(recent.starttime);
                    const endTime12 = convertTo12Hour(recent.endtime);
                    return (

                      <Card.Text key={index}>
                        <FaCalendarAlt className='mx-3' />
                        <p>{recent.class_title}-{recent.class_type} class</p>
                        <p>
                          Language:{recent.class_language}
                        </p>
                        <p>Date:{date} -  {startTime12} to {endTime12}</p>
                        <p>Session: {recent.class_mode}</p>

                      </Card.Text>
                    )
                  })

                ) : (
                  <Card.Text>
                    no course
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='m-5'>
          <h2 className='text-center fw-bold'><span className='text-danger fw-bold'>ALP</span> Astrology Training Classes</h2>
          <Col lg={4}>
            <h5 className='text-warning'>ALP Astrology Classes are given three levels</h5>
            <ul>
              <li>The Basic ALP Astrology training course</li>
              <li>The Advanced ALP Astrology training course</li>
              <li>The Master's ALP Astrology training course</li>
            </ul>
          </Col>
          <Col lg={8} className='mt-3'>


            <h5 className='text-warning'>The Advanced level ALP Thirumana Porutham training course is also available.</h5>
            <p>These courses are currently conducted online in both Tamil and English langugage. During Brahma Muhurat between 4.32am and 6.32am only. There is a growing demand to learn this evolutionary method in Astrology, hence work in progress in order to conduct classes in other national languages to cater to the current requirement. There are over 10,000 ALP astrologers now and the numbers are increasing by the day. The current ALP training batches hold a strength of over 2000 students. There is a team of 20 teachers of which 10 are Master level teachers and other 10 are basic level teachers and around 200 Coaches, who work a team and facilitate in conducting the classes. There is a qualifying examination conducted online on completion of the course and students are awarded a digital course completion.</p>


          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={6} xs={6}>
            <div className='course_box'>
              <div className='course_body'>
                <h4 className='fw-bold text-center'>Basic <span className='text-red'>ALP</span> Astrology</h4>
                <p>Fee : ₹ 5900</p>
                <Link to="https://api.whatsapp.com/send?phone=+918000115656&text=*ALPAstrology.online*%20Vanakkam,%20I%20would%20like%20to%20enquire%20For%20Basic%20ALP%20Astrology%20basic%20course." target='_blank'>
                  <Button className='btn-enquiry'>Enquiry</Button></Link>
              </div>

            </div>
          </Col>
          <Col lg={4} md={4} sm={6} xs={6}>
            <div className='course_box'>
              <div className='course_body'>
                <h4 className='fw-bold text-center'>Advance <span className='text-red'>ALP</span> Astrology</h4>
                <p>Eligible ALP Astrologers - Based on selection process By ALP Teachers </p>
                <Link to="https://api.whatsapp.com/send?phone=+918000115656&text=*ALPAstrology.online*%20Vanakkam,%20I%20would%20like%20to%20enquire%20For%20Advance%20ALP%20Astrology%20basic%20course." target='_blank'>
                  <Button className='btn-enquiry'>Enquiry</Button></Link>
              </div>

            </div>
          </Col>
          <Col lg={4} md={4} sm={6} xs={6}>
            <div className='course_box'>
              <div className='course_body'>
                <h4 className='fw-bold text-center'>Master <span className='text-red'>ALP</span> Astrology</h4>
                <p>Eligible ALP Astrologers - Based on selection process By Dr.S.Pothuvudaimoorthy </p>
              </div>

            </div>
          </Col>

        </Row>
        <Row>
          <h2 className='text-center fw-bold my-5'>Enquiry</h2>
          <p className='text-center fw-bold'>For inquiries and course registration, Please contact us at:</p>
          <Row>
            <Col lg={6} className='mx-auto'>
              <Form onSubmit={handleAddEnquiry}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={enquiry_name}
                    onChange={(e) => setEnquiryName(e.target.value)}
                    placeholder="Enter your name"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={enquiry_email}
                    onChange={(e) => setEnquiryEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                  {emailError && (
                    <small className='text-danger'>Please Enter the valid Email</small>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile</Form.Label>
                  <PhoneInput
                    international
                    type="tel"
                    value={enquiry_mobile}
                    onChange={(value) => setEnquiryMobile(value)}
                    placeholder="Enter your mobile number"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Course</Form.Label>
                  <Form.Select
                    className="form-control mb-3"
                    name="enquiry_course"
                    aria-label="Consultation Mode"
                    required
                    onChange={(e) => setEnquiryCourse(e.target.value)}
                  >
                            <option value="ALP Basic">ALP Basic</option>
                            <option value="ALP Advanced">ALP Advanced</option>

                    {upCommingCourse.length > 0 ? (
                      upCommingCourse.map((upcomming, index) => {
                        const date = new Date(upcomming.date).toLocaleDateString('en-GB');
                        const startTime12 = convertTo12Hour(upcomming.starttime);
                        const endTime12 = convertTo12Hour(upcomming.endtime);

                        return (
                          <>

                            <option key={index} value={upcomming.class_title}>
                              {upcomming.class_title} - {upcomming.class_type} class, {date}, {startTime12} to {endTime12}
                            </option>
                          </>
                        );
                      })
                    ) : (
                      <option value="" disabled selected>
                        No Class
                      </option>
                    )}
                  </Form.Select>
                </Form.Group>
                <Form.Group className=" revealx as_font14" style={{ color: 'black' }}>
                  <Form.Label>Language</Form.Label>
                  <Form.Select className="form-control mb-3" name="course_lang" aria-label="Consultation Mode" required onChange={(e) => setCourseLang(e.target.value)}>
                    <option>Select One</option>
                    <option value="Tamil">Tamil</option>
                    <option value="English">English</option>
                    <option value="Hindi">Hindi</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={enquiry_address}
                    onChange={(e) => setEnquiryAddress(e.target.value)}
                    placeholder="Enter your address"
                    required
                  />
                </Form.Group>
                <div className='text-center'>
                  <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Row>
      </Container >
      <Footer />
      <Chatbot />
    </>
  )
}

export default Courses