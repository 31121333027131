import React, { useEffect, useState } from 'react'
import Header from './Common/Header'
import SideBar from './Common/SideBar'
import Footer from './Common/Footer'
import axios from 'axios'
import { Table, Col } from 'react-bootstrap'
import { Button, Row } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import Modal from 'react-bootstrap/Modal'
import { GrAttachment } from "react-icons/gr";
import { Link } from 'react-router-dom'



function MyMessage() {
    const [Messages, setMessages] = useState([]);
    const [filterMessage, setFilterMessages] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [messagePerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null)
    const [replyMsg, setReplyMsg] = useState(null)

    const userId = localStorage.getItem('userId');
    const fetchMessages = () => {
        axios.get(`https://alpastrology.online/alpapi/User_api/users_messages_list?user_id=${userId}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': 'alp@123'
            }
        }).then((response) => {
            if (response.data.status === 'Success') {
                console.log(response)
                setMessages(response.data.data)

            }
        }).catch((error) => {
            console.error(error)
        })
    }
    useEffect(() => {
        fetchMessages()
    }, []);


    useEffect(() => {
        const filtered = Messages.filter((message) =>
            message.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            message.user_mob.toLowerCase().includes(searchTerm.toLowerCase()) ||
            message.user_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            message.created_dt.includes(searchTerm)
        )
        setFilterMessages(filtered);
        setCurrentPage(0)
    }, [searchTerm, Messages])


    const indexOfLastMessage = (currentPage + 1) * messagePerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagePerPage;
    const currentMessage = filterMessage.slice(indexOfFirstMessage, indexOfLastMessage)

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const handleView = (message) => {
        setSelectedMessage(message);
        setShowModal(true)
        setReplyMsg(null); // Reset reply message
        fetchReply(message.msg_apt_id);

    }

    const fetchReply = (apptId) => {
        const formData = new FormData();
        formData.append('appt_id', apptId)
        axios.post(`https://alpastrology.online/alpapi/User_api/get_appointment_and_replies`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': 'alp@123'
            }
        }).then((response) => {
            const res = response.data;
            if (res.status === 'Success' && res.replies.length > 0) {
                setReplyMsg(res.replies[0]);
                console.log('Reply message', res.replies[0]);
            } else {
                setReplyMsg(null);
            }
        }).catch((error) => {
            console.error(error);
        })
    }



    return (
        <>
            <Header />
            <SideBar />
            <div className="app-wrapper">

                <div className="app-content pt-3 p-md-3 p-lg-4">
                    <div className="container-xl ">
                        <div className="wl_header">
                            <h3>My Messsages</h3>

                        </div>
                        <div className="mb-3">
                            <Row className='justify-content-between'>
                                <Col lg={4}>
                                    <input
                                        type="text"
                                        className="form-control table_search"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Link to='/MakeMessage' className='btn btn-primary'>Make New Message</Link>
                                </Col>
                            </Row>

                        </div>
                        <Table striped bordered hover variant="light" responsive>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Requested On </th>
                                    <th>Request</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentMessage.length > 0 ? (
                                    currentMessage.map((message, index) => {
                                        const msgDate = new Date(message.created_dt).toLocaleDateString('en-GB');
                                        return (
                                            <tr key={index}>
                                                <td>{indexOfFirstMessage+index + 1}</td>
                                                <td>{message.user_name}</td>
                                                <td>{message.user_email}</td>
                                                <td>{message.user_mob}</td>
                                                <td>{msgDate}</td>
                                                <td>{message.user_req_type}</td>
                                                <td>
                                                    <Button onClick={() => handleView(message)}>View</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={7}>No Data...</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={Math.ceil(filterMessage.length / messagePerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination justify-content-center'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Mssage Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedMessage && (
                        <>
                            <p><strong>Name:</strong> {selectedMessage.user_name}</p>
                            <p><strong>Email:</strong> {selectedMessage.user_email}</p>
                            <p><strong>Mobile:</strong> {selectedMessage.user_mob}</p>
                            <p><strong>Gender:</strong> {selectedMessage.user_gender}</p>
                            <p><strong>Date:</strong> {new Date(selectedMessage.created_dt).toLocaleDateString('en-GB')}</p>
                            <p><strong>Date Of Birth:</strong> {selectedMessage.user_dob}</p>
                            <p><strong>Time Of Birth:</strong> {selectedMessage.user_tob}</p>
                            <p><strong>Place Of Birth:</strong> {selectedMessage.user_pob}</p>
                            <p><strong>Request:</strong> {selectedMessage.user_req_type}</p>
                            <p><strong>Request For:</strong> {selectedMessage.msg_request_for}</p>

                        </>
                    )}

                </Modal.Body>
                <Modal.Header >
                    <Modal.Title>Reply Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {replyMsg ? (
                        <>
                            <p><strong>Message:</strong><br /> {replyMsg.reply_message}</p>
                            <div className='attachment'>
                                <p><strong>Attachment:</strong></p>
                                <a href={replyMsg.file} download target="_blank" rel="noopener noreferrer">
                                    <GrAttachment /> View Attachment
                                </a>
                            </div>
                            <p><strong>Reply:</strong><br /> {new Date(replyMsg.created_at).toLocaleDateString('en-GB')}</p>
                        </>
                    ) : (
                        <p>No reply found.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MyMessage