import React, { useState, useEffect } from 'react';
import Header from './Common/Header';
import SideBar from './Common/SideBar';
import Footer from './Common/Footer';
import { Col, Row, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MakeMessage = () => {
    const [formData, setFormData] = useState({
        user_name: '',
        user_mob: '',
        user_gender:'',
        user_dob: '',
        user_tob: '',
        user_pob: '',
        user_email: '',
        user_for_id: '',
        user_req: '',
        user_req_for:''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [apt_user_country, setAptUserCountry] = useState('')
    const [apt_user_state, setAptUserState] = useState('')
    const [apt_user_dist, setAptUserDist] = useState('')
    const [appt_user_village, setAptUserVillage] = useState('')
    const [apt_user_pob, setAptUserPob] = useState('')
    const navigate =useNavigate();

    useEffect(() => {
        // Retrieve mobile and email from localStorage
        const user_for_id = localStorage.getItem('userId') || '';
        const user_email = localStorage.getItem('userEmail') || '';
        const user_mob = localStorage.getItem('userMobile') || '';
        setFormData((prevFormData) => ({
            ...prevFormData,
            user_email,
            user_mob,
            user_for_id
        }));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    useEffect(() => {
        const placeOfBirth = `${appt_user_village || ''}, ${apt_user_dist || ''}, ${apt_user_state || ''}, ${apt_user_country || ''}`.replace(/(^, )|((, )+$)/g, ''); // Handle empty values
        setAptUserPob(placeOfBirth);
    }, [apt_user_country, apt_user_state, apt_user_dist, appt_user_village]);


    

    const handleSubmit = async (e) => {
        e.preventDefault();
        
            setIsSubmitting(true); // Disable button

            console.log('Details Submitted:', formData);
            const form = new FormData();
            form.append('user_for_id', formData.user_for_id);
            form.append('user_name', formData.user_name);
            form.append('user_gender', formData.user_gender);
            form.append('user_mob', formData.user_mob);
            form.append('user_dob', formData.user_dob);
            form.append('user_tob', formData.user_tob);
            form.append('user_pob', apt_user_pob);
            form.append('user_email', formData.user_email);
            form.append('user_req', formData.user_req);
            form.append('user_req_for',formData.user_req_for)

            try {
                const response = await fetch('https://alpastrology.online/alpapi/User_api/make_message_appt', {
                    method: 'POST',
                    headers: {
                        'x-api-key': 'alp@123'
                    },
                    body: form
                });

                if (response.ok) {

                    const notifData = new FormData();
                    notifData.append('title', 'ALP Online');
                    notifData.append('message', formData.user_name + ' Sent a new Message request');

                    axios.post(
                        'https://alpastrology.online/alpapi/User_api/notification_send',
                        notifData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'x-api-key': 'alp@123',
                            },
                        }
                    )



                    const data = await response.json();
                    console.log('Response from server:', data);
                    alert("Thank You! Your ALP Horoscope will be sent shortly");
                    navigate('/Mymessage')

                    // Reset the form after successful submission, keeping some data from localStorage
                    setFormData({
                        user_name: '',
                        user_mob: localStorage.getItem('userMobile') || '',
                        user_dob: '',
                        user_tob: '',
                        user_gender:'',
                        user_email: localStorage.getItem('userEmail') || '',
                        user_for_id: localStorage.getItem('userId') || '',
                        user_req: '',
                        user_req_for:''
                    });
                    setAptUserCountry('');
                    setAptUserDist('');
                    setAptUserState('');
                    setAptUserVillage('')
                    
                } else {
                    console.error('Error submitting the details form.');
                    alert('Error submitting the form');
                }
            } catch (error) {
                console.error('Error:', error);
                alert('An error occurred while sending the message');
            }
       
    };

    return (
        <>
            <Header />
            <SideBar />
            <div className="app-wrapper">
                <div className="app-content pt-3 p-md-3 p-lg-4">
                    <div className="container-xl ">
                        <form className="details-forms" onSubmit={handleSubmit}>
                            <h2>Make New Message</h2>
                            <Row>
                                <Col lg={4}>
                                    <div>
                                        <label>Name:</label>
                                        <input
                                            type="text"
                                            name="user_name"
                                            value={formData.user_name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div>
                                        <label>Gender</label>
                                        <select
                                            name="user_gender"
                                            value={formData.user_gender}
                                            onChange={handleChange}
                                            className='form-control'
                                            required
                                        >
                                            <option value=''>Select</option>
                                            <option value='Male'>Male</option>
                                            <option value='Female'>Female</option>
                                            <option value='Other'>Other</option>
                                            
                                        </select>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div>
                                        <label>Date of Birth:</label>
                                        <input
                                            type="date"
                                            name="user_dob"
                                            value={formData.user_dob}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </Col>

                                <Col lg={4}>
                                    <div>
                                        <label>Time of Birth:</label>
                                        <input
                                            type="time"
                                            name="user_tob"
                                            value={formData.user_tob}
                                            onChange={handleChange}
                                            step={1}
                                            required
                                        />
                                    </div>
                                </Col>
                              
                                <Col lg={4}>
                                    <div>
                                        <label>Do you want?</label>
                                        <select
                                            name="user_req"
                                            value={formData.user_req}
                                            onChange={handleChange}
                                            className='form-control'
                                            required
                                        >
                                            <option value=''>Select</option>
                                            <option value='ALP Astrology'>ALP Astrology</option>
                                            <option value='ALP Vastu Shastra'>ALP Vastu Shastra</option>
                                            <option value='ALP Numerology'>ALP Numerology</option>
                                            <option value='ALP Prasanam'>ALP Prasanam</option>
                                            <option value='ALP Gemology'>ALP Gemology</option>
                                            <option value='ALP Marriage Matching'>ALP Marriage Matching</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div>
                                        <label>Request For</label>
                                        <select
                                            name="user_req_for"
                                            value={formData.user_req_for}
                                            onChange={handleChange}
                                            className='form-control'
                                            required
                                        >
                                            <option value=''>Select</option>
                                            <option value='Self'>Self</option>
                                            <option value='Wife'>Wife</option>
                                            <option value='Children'>Children</option>
                                            <option value='Mother'>Mother</option>
                                            <option value='Father'>Father</option>
                                            <option value='Brother'>Brother</option>
                                            <option value='Sister'>Sister</option>
                                            <option value='Others'>Others</option>
                                        </select>
                                    </div>
                                </Col>
                                <Form.Group>
                                    <Form.Label>Place of Birth Details</Form.Label>
                                </Form.Group>
                                <Col lg={4} md={4} sm={12}>
                                    <Form.Group className="revealx as_font14">
                                    <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="apt_user_country"
                                            placeholder=""
                                            className="form-control"
                                            onChange={(e) => setAptUserCountry(e.target.value)}
                                            required
                                        />
                                        <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <Form.Group className="revealx as_font14">
                                    <Form.Label>State</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="apt_user_state"
                                            placeholder=""
                                            className="form-control"
                                            onChange={(e) => setAptUserState(e.target.value)}
                                            required
                                        />
                                        <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <Form.Group className="revealx as_font14">
                                    <Form.Label>District</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="apt_user_dist"
                                            placeholder=""
                                            className="form-control"
                                            onChange={(e) => setAptUserDist(e.target.value)}
                                            required
                                        />
                                        <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <Form.Group className="revealx as_font14">
                                    <Form.Label>Village/Town</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="appt_user_village"
                                            placeholder=""
                                            className="form-control"
                                            onChange={(e) => setAptUserVillage(e.target.value)}
                                            required
                                        />
                                        <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                    </Form.Group>
                                    <Form.Group className="revealx as_font14" style={{ color: 'black' }}>
                                        <Form.Group className="revealx as_font14">
                                            <Form.Control
                                                type="hidden"
                                                placeholder="Place of Birth"
                                                name="apt_user_pob"
                                                className="form-control"
                                                value={apt_user_pob} // Dynamically updated value
                                                readOnly
                                            />
                                            <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                        </Form.Group>
                                    </Form.Group>
                                </Col>

                            </Row><br />
                            <button type="submit" disabled={isSubmitting}>
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default MakeMessage;
